import { useSearchParams as useRouterParams } from "react-router-dom"

const useSearchParams = () => {
  const [searchParams, setSearchParams] = useRouterParams()

  const updateParams = (params: { [name: string]: string }) => {
    const newParams = new URLSearchParams(searchParams)
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined || value === "") {
        newParams.delete(key)
      } else {
        newParams.set(key, value)
      }
    })
    setSearchParams(newParams, { replace: true, preventScrollReset: true })
  }


  return [searchParams, updateParams]
}

export default useSearchParams
