import React, { useContext, useState } from "react"
import { Button, Dialog, DialogContent, Divider } from "@mui/material"
import CloseButton from "../common/CloseButton"
import SettingsIcon from '@mui/icons-material/Settings'
import { MassEditFAQs } from "./MassEditFAQs"
import { FollowUpdatesChannel } from "./FollowUpdatesChannel"
import { SelectRespondChannels } from "./SelectRespondChannels"
import { MessageTranslations } from "./MessageTranslations"
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { useViewport } from "../../util/useViewport"
import CampaignIcon from '@mui/icons-material/Campaign'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import TranslateIcon from '@mui/icons-material/Translate'
import EditRoadIcon from '@mui/icons-material/EditRoad'
import AppContext from "../../context/AppContext";

export const MoreSettings = () => {

  const { guild } = useContext(AppContext)


  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState("follow-updates")

  const { isMobile } = useViewport()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<SettingsIcon/>}
        onClick={() => setOpen(true)}
      >
        Configure
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{ sx: { maxHeight: '90vh', minHeight: '900px' } }}
        fullWidth
        onClose={(e, r) => {
          if (r !== "backdropClick") {
            setOpen(false)
          }
        }}
      >
        <div className="ml-auto">
          <CloseButton onClick={() => setOpen(false)}/>
        </div>

        <h2 className="text-center text-gray-400 mt-[-20px] mb-3 text-lg">Settings for {guild?.name}</h2>
        <Divider className="bg-gray-800"/>
        <DialogContent className={"text-center mt-0 mb-10 mx-4" + (isMobile ? "" : " flex")}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', minWidth: '300px' }}>
              <TabList
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                orientation="vertical"
              >
                <Tab value="follow-updates" label="Follow Updates" className="self-start" icon={<CampaignIcon/>} iconPosition="start"/>
                <Tab value="select-channels" label="Chatbot Channels" className="self-start" icon={<QuestionAnswerIcon/>}
                     iconPosition="start"/>
                <Tab value="edit-translations" label="Edit Messages" className="self-start" icon={<TranslateIcon/>}
                     iconPosition="start"/>
                <Tab value="mass-edit-faqs" label="Mass Edit FAQs" className="self-start" icon={<EditRoadIcon/>} iconPosition="start"/>
              </TabList>
            </Box>
            <div className="mx-auto">
              <TabPanel value="follow-updates">
                <FollowUpdatesChannel/>
              </TabPanel>
              <TabPanel value="select-channels">
                <SelectRespondChannels/>
              </TabPanel>
              <TabPanel value="edit-translations">
                <MessageTranslations/>
              </TabPanel>
              <TabPanel value="mass-edit-faqs">
                <MassEditFAQs closeModal={() => setOpen(false)}/>
              </TabPanel>
            </div>
          </TabContext>
        </DialogContent>
      </Dialog>
    </div>
  )

}
