// Originally machine translated so these may not be 100% accurate
export const translations = [
  {
    "language": "en",
    "icon": "\uD83C\uDDFA\uD83C\uDDF8",
    "translations": {
      "similar_question": {
        "title": "A similar question may have been answered",
        "question": "Question",
        "answer": "Answer",
        "confidence": "Confidence"
      }
    }
  },
  {
    "language": "es",
    "icon": "\uD83C\uDDEA\uD83C\uDDF8",
    "translations": {
      "similar_question": {
        "title": "Es posible que ya se haya respondido a una pregunta similar",
        "question": "Pregunta",
        "answer": "Respuesta",
        "confidence": "Confianza"
      }
    }
  },
  {
    "language": "fr",
    "icon": "\uD83C\uDDEB\uD83C\uDDF7",
    "translations": {
      "similar_question": {
        "title": "Une réponse à une question similaire a peut-être déjà été apportée",
        "question": "Question",
        "answer": "Answer",
        "confidence": "Confiance"
      }
    }
  },
  {
    "language": "de",
    "icon": "\uD83C\uDDE9\uD83C\uDDEA",
    "translations": {
      "similar_question": {
        "title": "Eine ähnliche Frage wurde vielleicht schon beantwortet",
        "question": "Frage",
        "answer": "Antwort",
        "confidence": "Zuversicht"
      }
    }
  },
  {
    "language": "it",
    "icon": "\uD83C\uDDEE\uD83C\uDDF9",
    "translations": {
      "similar_question": {
        "title": "Una domanda simile potrebbe aver già ricevuto una risposta",
        "question": "Domanda",
        "answer": "Risposta",
        "confidence": "Fiducia"
      }
    }
  },
  {
    "language": "pt",
    "icon": "\uD83C\uDDF5\uD83C\uDDF9",
    "translations": {
      "similar_question": {
        "title": "Uma pergunta semelhante pode já ter sido respondida",
        "question": "Pergunta",
        "answer": "Resposta",
        "confidence": "Confiança",
      }
    }
  }, {
    "language": "ru",
    "icon": "\uD83C\uDDF7\uD83C\uDDFA",
    "translations": {
      "similar_question": {
        "title": "Возможно, на подобный вопрос уже был дан ответ",
        "question": "Вопрос",
        "answer": "Ответ",
        "confidence": "Уверенность"
      }
    }
  }, {
    "language": "pl",
    "icon": "\uD83C\uDDF5\uD83C\uDDF1",
    "translations": {
      // By Timury#6793
      "similar_question": {
        "title": "Możliwe, że odpowiedź na podobne pytanie została już udzielona",
        "question": "Pytanie",
        "answer": "Odpowiedź",
        "confidence": "Pewność"
      }
    }
  },
  {
    "language": "fi",
    "icon": "\uD83C\uDDEB\uD83C\uDDEE",
    "translations": {
      "similar_question": {
        "title": "Samanlaiseen kysymykseen saattaa olla vastaus",
        "question": "Kysymys",
        "answer": "Vastaus",
        "confidence": "Luottamus"
      }
    }
  }, {
    "language": "pirate",
    // pirate flag emoji
    "icon": "\uD83C\uDFF4\u200D\u2620\uFE0F",
    "translations": {
      "similar_question": {
        "title": "Arrr, it be seemin' that someone has already asked a question much like yours, matey. Have a gander at the answer below, ye scurvy dog.",
        "question": "What be yer question?",
        "answer": "Here be the answer",
        "confidence": "How sure be I in me answer?"

      }
    }
  },
  {
    "language": "yoda",
    // milky way emoji
    "icon": "\uD83C\uDF0C",
    "translations": {
      "similar_question": {
        "title": "A similar question, already asked and answered, it seems. Below, the answer see",
        "question": "The question, what was it?",
        "answer": "Here, the answer is",
        "confidence": "Confidence, in my answer how much?"
      }
    }
  }, {
    "language": "dalek",
    // robot emoji
    "icon": "\uD83E\uDD16",
    "translations": {
      "similar_question": {
        "title": "DETECTION: A SIMILAR QUESTION HAS ALREADY BEEN POSED AND ADDRESSED BY ONE OF OUR KIN.",
        "question": "WHAT WAS THE QUESTION, HUMAN?",
        "answer": "HERE IS THE ANSWER, AS PREVIOUSLY DETERMINED BY THE WISDOM OF THE EMPIRE.",
        "confidence": "WE ARE NOT SUBJECT TO ERROR, HUMAN."
      }
    }
  }
]
