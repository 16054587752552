import client from "../config/axios"
import { FAQ } from "../model/FAQ"
import debug from "../config/debug"
import { Config } from "../model/Config"
import { Suggestion } from "../model/Suggestion"

export type ServerDataResponse = { config: Config, faqs: FAQ[] }
export type SuggestionsResponse = { suggestions: Suggestion[] }

export async function getServerData(guildId: string): Promise<ServerDataResponse> {
  const data = await client.get("/server/" + guildId)
  debug(`guild${guildId} data: ${data.data}`)
  return data.data
}

export async function saveFAQs(guildId: string, faqs: FAQ[]): Promise<ServerDataResponse> {
  const res = await client.post("/faqs/" + guildId, faqs)
  return res.data
}

export async function saveConfig(guildId: string, config: Partial<Config>) {
  return client.post("/config/" + guildId, config)
}

export async function fetchSuggestions(guildId: string): Promise<SuggestionsResponse> {
  const res = await client.get("/suggestions/" + guildId)
  return res.data
}
