import React, { useContext, useState } from "react"
import { Badge, IconButton, Link, Menu, MenuItem } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { logout } from "../service/userService"
import AppContext from "../context/AppContext"
import { HOME_WEBSITE_URL } from "../config/urls"

export default function AccountMenu() {

  const { user } = useContext(AppContext)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const iconUrl = getAvatarUrl(user?.id, user?.avatar)

  const allNotifications = 0 // TODO: add notifications here

  if (!user) {
    return null
  }

  // @ts-ignore
  return (
    <div>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="large" className="h-10 w-10 hover:scale-110">
        <Badge classes={{ root: "", badge: "right-1 top-1" }} color="secondary" badgeContent={allNotifications}>
          {user?.avatar ? (
            <img src={iconUrl} className="w-10 h-10 rounded-full" alt=""/>
          ) : (
            <AccountCircleIcon className="text-gray-400" fontSize="large"/>
          )}
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <Link
            style={{ textDecoration: 'none' }}
            onClick={async () => {
              await logout({ redirect: HOME_WEBSITE_URL })
            }}
          >
            <ExitToAppIcon className="text-white"/>
            <span className="ml-1 text-gray-400">Logout</span>
          </Link>
        </MenuItem>
      </Menu>

    </div>
  )
}

const getAvatarUrl = (userId: string, avatarId: string) => {
  return `https://cdn.discordapp.com/avatars/${userId}/${avatarId}.png`
}
