import React, { useContext, useState } from "react"
import { Button, Paper, Slider, Tooltip, Typography } from "@mui/material"
import { saveFAQs } from "../service/faqService"
import AppContext from "../context/AppContext"
import { FAQ } from "../model/FAQ"
import HelpIcon from '@mui/icons-material/Help'
import SearchField, { useSearch } from "./SearchFAQs"
import ModelSelector from "./settings/ModelSelector"
import Tips from "./Tips"
import { VoteBotModal } from "./VoteBotModal"
import { Config } from "../model/Config"
import Suggestions from "./Suggestions"
import { MoreSettings } from "./settings/MoreSettings"
import { ManageSubscriptionButton } from "./ManageSubscriptionButton"
import { ListFAQs } from "./ListFaqs"
import { EditFAQ } from "./EditFAQ"
import { ErrorBoundary } from "./common/ErrorBoundary"
import { PLANS_WEBSITE_URL, WALLU_INVITE_URL } from "../config/urls"
import { Suggestion } from "../model/Suggestion"


interface EditFAQsProps {
  loading: boolean
}

export function EditFAQsPage({ loading }: EditFAQsProps) {

  const { guild, setFAQs, faqs: allFaqs } = useContext(AppContext)

  const { search, setSearch, faqs: searchFaqs } = useSearch(allFaqs)

  const [openVoteModal, setOpenVoteModal] = useState(false)
  const [initialFAQ, setInitialFAQ] = useState<Partial<FAQ> | undefined>()

  const Header = (
    <div className="text-left w-fit mb-6">
      <Tips/>
    </div>
  )

  if (loading) {
    return Header
  }

  if (!guild) {
    return (
      <div>
        {Header}
        <p className="text-gray-500">
          Select a server to continue.
        </p>
      </div>
    )
  }

  // @ts-ignore
  const status = allFaqs?.response?.status
  if (status === 404) {
    return <NotOnThatServer header={Header}/>
  }
  if (status === 403) {
    return (
      <div>
        <Typography style={{ fontSize: '22px' }} className="text-red-400">
          You do not have permission to edit this server.
        </Typography>
        <p className="text-gray-500">
          Editing the server requires the <code>Administrator</code> permission.
        </p>
      </div>
    )
  }

  const save = async (faq: FAQ) => {
    if (allFaqs?.length > 4) {
      setOpenVoteModal(true) // prompt but still save
    }
    const isNewFaq = !faq.id
    const other = allFaqs.filter(it => it.id !== faq.id)
    const newFaqs = [...other, faq]
    try {
      const saved = await saveFAQs(guild!!.id, newFaqs)
      setFAQs(saved.faqs)
      if (isNewFaq) {
        setInitialFAQ(undefined)
      }
    } catch (err) {
      window.alert(`Failed to save ${err}`)
      throw err
    }
    return faq
  }

  return (
    <div>
      {Header}
      <div className="text-left w-fit">
        <Paper className="my-4 p-4 flex gap-8 items-center flex-wrap-reverse">
          <SearchField onSearchUpdate={setSearch}/>
          <ModelSelector guildId={guild?.id}/>
          <ManageSubscriptionButton/>
          <MoreSettings/>
        </Paper>
        <div className="text-gray-500 m-2">
          {searchFaqs.length === 0 ? (
            <p>No FAQs found {search ? ` for "${search}"` : ""}</p>
          ) : (
            <p>{searchFaqs.length} FAQs found {search ? ` for "${search}"` : ""}</p>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-4 mb-6 gap-4 flex-wrap lg:flex-nowrap">
        <div className="w-full lg:w-1/2">
          <NewFaq save={save} initialFAQ={initialFAQ}/>
        </div>
        <div className="w-full lg:w-1/2">
          <ErrorBoundary>
            {guild?.id &&
              <Suggestions
                guildId={guild.id}
                // Refresh when faqs are added, TODO: optimize
                refreshId={JSON.stringify(allFaqs)}
                addQuestion={(suggestion: Suggestion) => {
                  setInitialFAQ({
                    'question': suggestion.question,
                    'answer': suggestion.answer,
                  })
                  // focus on the first "Question" field
                  // @ts-ignore
                  document.querySelector("#new-faq-question")?.focus()
                }}
              />
            }
          </ErrorBoundary>
        </div>
      </div>
      {openVoteModal && (<VoteBotModal/>)}
      <div className="pb-32">
        <ListFAQs
          faqs={searchFaqs}
          save={save}
          deleteFAQ={async faq => {
            const questionFirstLine = faq.question.split('\n')[0]
            if (window.confirm(`Are you sure you want to delete FAQ: "${questionFirstLine}"?`)) {
              const newFaqs = allFaqs.filter(it => it.id !== faq.id)
              const saved = await saveFAQs(guild!!.id, newFaqs)
              setFAQs(saved.faqs)
            }
          }}
        />
      </div>
    </div>
  )
}


function NewFaq({ save, initialFAQ }: { save: (faq: FAQ) => Promise<FAQ>, initialFAQ?: Partial<FAQ> }) {

  const { faqs, config } = useContext(AppContext)

  // @ts-ignore
  const faq: FAQ = { id: '-1', question: [], answer: '', ...initialFAQ }

  return (
    <div>
      <EditFAQ isNewFAQ faq={faq} save={saveNewFaq(config!!, faqs, save)}/>
    </div>
  )
}

const saveNewFaq = (config: Config, faqs: any[], save: (faq: FAQ) => Promise<FAQ>): (faq: FAQ) => Promise<FAQ> => {
  return async (faq) => {
    if (faqs.length >= config.limits.faqs) {
      faqLimitPopup(config)
      throw new Error("FAQ limit reached")
    } else {
      // @ts-ignore
      delete faq.id
      await save(faq)
      return faq
    }
  }
}

const faqLimitPopup = (config: Config) => {
  if (window.confirm(`Unfortunately, your plan is limited to ${config.limits.faqs} FAQs.\nWould you like to check our other plans?`)) {
    window.open(PLANS_WEBSITE_URL, '_blank')
  }
}


export const ConfidenceSlider = ({ field, ...props }: any) => {
  return (
    <div className="max-w-[400px]" {...props}>
      <Typography gutterBottom className="text-left">
        Minimum confidence
        <Tooltip
          title={
            <span className="text-base">
                                            How confident should Wallu be before answering the question?
                                            <br/>
                                            <br/>
                                            <u>
                                                <b>Recommended: 0.7-0.9</b>
                                            </u>
                                            <br/>
                                            <br/>
                                            Lower values will make the chatbot more likely to answer (more aggressive), but also more likely to answer incorrectly.
                                        </span>
          }>
          <span> <HelpIcon/></span>
        </Tooltip>
        <br/>
      </Typography>
      <Slider
        {...field}
        step={0.01}
        min={0.5}
        valueLabelDisplay="auto"
        max={1}
        marks={[{ value: 0.5, label: "50%" }, { value: 1, "label": "100%" }]}
      />
    </div>
  )
}
export const NotOnThatServer = ({ header }: any) => {
  return (
    <div>
      {header}
      <Typography style={{ fontSize: '22px' }} className="text-red-400">
        The bot is not on that server.
      </Typography>
      <div className="mt-6">
        <Button
          href={WALLU_INVITE_URL}
          target={"_blank"}
          variant="contained"
        >Add to server</Button>
      </div>
    </div>
  )
}
