import React, { useContext } from "react"
import AppContext from "../context/AppContext"
import { Controller, useForm, useFormState } from "react-hook-form"
import { FAQ } from "../model/FAQ"
import { useObjectChangeEffect } from "../util/useObjectChangeEffect"
import { Button, Paper, TextField, Typography } from "@mui/material"
import { ConfidenceSlider } from "./EditFAQsPage"
import { Config } from "../model/Config"
import { PLANS_WEBSITE_URL } from "../config/urls"

interface EditFAQProps {
  faq: FAQ
  save: (faq: FAQ) => Promise<FAQ>
  deleteFAQ?: () => Promise<any>
  isNewFAQ?: boolean
}

const toFormFAQ = (faq: FAQ): FAQ => {
  return {
    ...faq,
    question: [faq.question, faq.alternatives?.map(it => it.question)].flat().filter(Boolean).join('\n')
  }
}

const fromFormFAQ = (formFAQ: FAQ): FAQ => {
  const faq = { ...formFAQ } // clone so the editor won't break because of these mappings
  const questions = faq.question.split('\n')
  faq.question = questions[0]?.trim()
  faq.alternatives = questions.slice(1)
    .map(it => it.trim())
    .filter(Boolean)
    .map(it => {
      return {
        question: it,
        threshold: faq.threshold, // TODO: make user editable?
      }
    })
  return faq
}

const alternativeLimitPopup = (config: Config) => {
  if (window.confirm(`Unfortunately, your plan is limited to ${config.limits.alternatives} alternative questions.\nWould you like to check our other plans?`)) {
    window.open(PLANS_WEBSITE_URL, '_blank')
  }
}

export function EditFAQ({ faq, save, deleteFAQ, isNewFAQ }: EditFAQProps) {

  const { faqs, config } = useContext(AppContext)

  const { handleSubmit, control, reset, watch } = useForm<FAQ>({
    defaultValues: toFormFAQ(faq),
    mode: 'onChange',
  })
  const { isDirty, errors, isValid } = useFormState({ control })

  useObjectChangeEffect(() => {
    reset(toFormFAQ(faq), { keepDefaultValues: true })
  }, [faq])

  const submitFAQ = (faq: FAQ) => {
    const dtoFAQ = fromFormFAQ(faq)

    if (dtoFAQ.alternatives.length > config.limits.alternatives) {
      alternativeLimitPopup(config)
    }

    save(dtoFAQ).then(faq => {
      if (faq) {
        reset(isNewFAQ ? {} : toFormFAQ(faq))
      }
    })
  }

  const createdTimestampFormat = (faq: FAQ) => {
    const created = new Date(faq.created_at + " UTC") // FIXME: just hacky, because I hate python
    const diff = Math.round((Date.now() - created.getTime()) / 1000)
    const rtf1 = new Intl.RelativeTimeFormat()
    if (diff < 60) {
      return rtf1.format(-diff, 'second')
    }
    if (diff < 60 * 60) {
      return rtf1.format(-Math.round(diff / 60), 'minute')
    }
    if (diff < 60 * 60 * 24) {
      return rtf1.format(-Math.round(diff / 60 / 60), 'hour')
    }
    if (diff < 60 * 60 * 24 * 7) {
      return rtf1.format(-Math.round(diff / 60 / 60 / 24), 'day')
    }
    return created.toLocaleString()
  }

  return (
    <Paper className="p-8">
      {isNewFAQ &&
        <Typography
          color="secondary"
          style={{
            margin: '0 0 8px 0',
            fontSize: '22px',
            textAlign: 'start'
          }}
        >Add a new FAQ</Typography>
      }
      <form className="flex flex-col gap-5" onSubmit={handleSubmit(submitFAQ)}>
        <Controller
          name={"question"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              id={isNewFAQ ? "new-faq-question" : undefined}
              error={!!errors?.["question"]}
              helperText={errors?.["question"]?.message}
              onChange={onChange}
              value={value}
              label={"Questions (one per line)"}
              placeholder={"What is the meaning of life?\nWhat is the answer to the ultimate question of life, the universe, and everything?"}
              multiline
              rows={3}
              // maxRows={(config?.limits?.alternatives || 1) + 3}
              // TODO: show plan message when going pass 2 faqs
            />
          )}
        />

        <Controller
          name={"answer"}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              error={!!errors?.["answer"]}
              helperText={errors?.["answer"]?.message}
              InputLabelProps={{ shrink: !!value }} // Fixes label overlapping when value is programmatically set
              onChange={onChange}
              value={value}
              rows={5}
              label={"Answer"}
              multiline
            />
          )}
        />
        <Controller
          name="threshold"
          defaultValue={0.8}
          control={control}
          render={({ field }) => (
            <ConfidenceSlider field={field}/>
          )}
        />
        <div className="flex flex-row justify-end items-center space-x-4">
          <div className="text-left text-xs text-gray-400 mr-auto self-end">
            {faq.created_at && (
              <span>
                               Created {createdTimestampFormat(faq)}
                            </span>
            )}
          </div>
          {deleteFAQ && !isNewFAQ && (
            <Button
              style={{ color: 'red' }}
              variant={"outlined"}
              onClick={deleteFAQ}
            >Delete</Button>
          )}
          <Button
            disabled={!isDirty}
            variant={"outlined"}
            onClick={() => reset()}
          >Reset</Button>
          <Button
            disabled={!isDirty || !isValid}
            variant={"contained"}
            onClick={handleSubmit(submitFAQ)}
          >Save</Button>
          {!isNewFAQ && watch(['question', 'answer']).filter(Boolean)?.length === 2 && (
            <Button
              variant={"outlined"}
              onClick={async () => {
                // @ts-ignore
                await saveNewFaq(config, faqs, save)({
                  question: watch('question'),
                  answer: watch('answer')
                })
                window.scrollTo(0, 0)
              }}
            >Clone</Button>
          )}
        </div>
      </form>
    </Paper>
  )
}
