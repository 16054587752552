import { Paper } from "@mui/material"

export default function Tips() {
  return (
    <Paper className="p-4">
      <h3 className="text-xl">Tips:</h3>
      <ul className="list-disc ml-6">
        <li>
          Write the FAQs like users would ask them. Use slang your users use. Consider whether they use "cryptocurrency" or
          "crypto", "youtuber role" or "yt role" etc.
        </li>
        <li>
          If the bot is interrupting conversation/annoying users, try increasing the <i>minimum confidence</i> so it will
          not respond to questions that it is not confident about.
        </li>
        <li>
          If specific questions are often misinterpreted, try increasing the threshold, rephrasing the question or completely
          removing it.
        </li>
        <li>
          There is no need to add very similar questions. For example, adding both "How do I get a role?" and "How can you get
          a rank?" is not usually necessary.
        </li>
      </ul>
    </Paper>
  )
}
