import React, { useContext, useState } from "react"
import AppContext from "../../context/AppContext"
import useRequest from "../../useRequest"
import { ChannelsResponse, getChannels } from "../../service/discordService"
import { saveConfig } from "../../service/faqService"
import { Channel } from "../../model/Channel"
import { Autocomplete, Button, FormControl, LinearProgress, TextField } from "@mui/material"
import { SUPPORT_URL, WALLU_INVITE_URL } from "../../config/urls"

export const FollowUpdatesChannel = () => {

  const { guild, config, setConfig } = useContext(AppContext)

  const { loading, data, error } = useRequest<ChannelsResponse>(() => getChannels(guild?.id || ""), { depends: [guild] })

  const [channel, setChannel] = useState<string | null>(null)

  const saveChannel = async (channel?: string | null) => {
    await saveConfig(guild?.id!!, { ...config, updates_destination: channel })
      .finally(() => {
        // @ts-ignore
        setConfig({ ...config, updates_destination: channel })
      })
      .catch(err => {
        alert("Failed to save channels: " + err)
      })
  }

  const options = (data?.channels ? data?.channels : [])
    .filter(it => it.type !== "category")
    .sort((a, b) => a.position - b.position)

  const channelId = channel || config?.updates_destination
  // @ts-ignore
  const value: Channel = options.find(c => c.id === channelId) || (channelId ? {
    id: -1,
    position: -3,
    name: "Deleted/Unknown Channel?"
  } : null)

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold">Where should important updates be sent?</h2>

      <div className="mt-6 w-2/3 m-auto">
        {loading && <LinearProgress className="mb-4"/>}
        {error && <p className="text-red-500">Error loading channels</p>}
        {data?.channels && (
          <FormControl fullWidth>
            <Autocomplete
              value={value}
              options={options}
              isOptionEqualToValue={(a, b) => a?.id === b?.id}
              renderInput={(params) => <TextField {...params} label="Channel"/>}
              getOptionLabel={(option) => {
                const name = option?.name
                return !option?.has_view_permissions ? `${name} (no permission)` : name
              }}
              getOptionDisabled={g => !g.has_view_permissions}
              onChange={(e, value) => {
                value && setChannel(value?.id)
              }}
            />
          </FormControl>
        )}

        {!value?.has_webhook_permission && (
          <p className="text-red-500 mt-2">
            The bot is missing some permissions for this. Please
            <a href={WALLU_INVITE_URL} className="text-blue-400" target="_blank"> authorize </a>
            the bot with new permissions or manually follow the channel on the
            <a href={SUPPORT_URL} className="text-blue-400" target="_blank" rel="noreferrer"> Discord Server</a>.
          </p>
        )}

        <div className="mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (window.confirm(`Updates will be sent to ${value.name}. You can remove this in the channel's settings.`)) {
                await saveChannel(channelId)
              }
            }}
            disabled={config?.updates_destination === channelId}
          >
            Save
          </Button>

          <p className="text-gray-300 mt-2 text-sm">
            It may take a few minutes.
          </p>

          <details className="text-gray-500 mt-4 cursor-pointer">
            <summary>Not working?</summary>

            <Button
              size="small"
              variant="text"
              color="secondary"
              onClick={async () => {
                if (window.confirm("It may take a few minutes for the bot to update. Also, check it has correct permissions.")) {
                  await saveChannel(null)
                }
              }}
            >
              Try again
            </Button>
          </details>
        </div>
      </div>
    </div>
  )
}
