import useRequest from "../useRequest"
import { getProfile } from "../service/userService"
import { Autocomplete, FormControl, LinearProgress, TextField } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { BASE_URL } from "../config/axios"
import AppContext from "../context/AppContext"

interface GuildSelectorProps {
  initialGuild: string
}

export default function GuildSelector({ initialGuild }: GuildSelectorProps) {

  const { guild, setGuild, setUser } = useContext(AppContext)

  // @ts-ignore
  const { loading, data, error } = useRequest(getProfile, data => data.guilds.retry_after)
  const loadingOptions = { id: "0", name: loading ? "Loading..." : "No server selected" }
  const options = [...(data?.guilds || []), loadingOptions]
    .sort((x, y) => Number(y?.is_admin) - Number(x?.is_admin))

  useEffect(() => {
    if (data?.user) {
      setUser(data.user)
    }
    if (!guild) {
      if (initialGuild) {
        const found = options.find(it => it.id === initialGuild)
        // @ts-ignore
        if (found) {
          setGuild(found)
        }
      }
    }
  }, [data])

  if (error?.response && [401, 403].includes(error.response.status)) {
    window.location.href = `${BASE_URL}/auth`
  }

  return (
    <div>
      <FormControl fullWidth>
        {loading && <LinearProgress/>}
        <Autocomplete
          value={guild || loadingOptions}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            value && setGuild(value)
          }}
          options={options}
          loading={loading}
          noOptionsText="Not found..."
          autoHighlight
          getOptionDisabled={g => !g.is_admin}
          renderInput={(params) => (
            <TextField {...params} variant="standard"/>
          )}
        />
      </FormControl>
    </div>
  )
}
