import React, { useContext, useState } from "react"
import { Controller, useForm, useFormState } from "react-hook-form"
import AppContext from "../../context/AppContext"
import { saveFAQs } from "../../service/faqService"
import { ConfidenceSlider } from "../EditFAQsPage"
import { Button, LinearProgress } from "@mui/material"

interface EditValues {
  threshold: number
}

export const MassEditFAQs = ({ closeModal }: any) => {

  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, reset } = useForm<EditValues>({ defaultValues: { threshold: 0.75 } })
  const { isDirty } = useFormState({ control })

  const { guild, faqs, setFAQs } = useContext(AppContext)

  const update = async (values: EditValues) => {
    try {
      if (window.confirm("Are you sure? This will affect all FAQs")) {
        setLoading(true)
        const newFaqs = faqs.map(faq => ({
          ...faq, threshold: values.threshold
        }))
        const saved = await saveFAQs(guild!!.id, newFaqs)
        setFAQs(saved.faqs)
        reset()
        setLoading(false)
        closeModal()
      }
    } catch (err) {
      window.alert("Error updating FAQs")
    }
  }

  return (
    <div>
      <h1 className="text-2xl my-2">Edit All FAQs at once</h1>
      <p className="my-4">
        This will update all FAQs at once. You cannot undo these changes.
      </p>
      <form className="flex flex-col items-center w-full mt-12" onSubmit={handleSubmit(update)}>
        <Controller
          name="threshold"
          defaultValue={0.8}
          control={control}
          render={({ field }) => (
            <ConfidenceSlider className="w-4/5" field={field}/>
          )}
        />
        <div className="flex flex-row justify-end items-center space-x-4 mt-10">
          <Button
            disabled={!isDirty || loading}
            variant={"outlined"}
            onClick={() => reset()}
          >Reset</Button>
          <Button
            disabled={!isDirty || loading}
            variant={"contained"}
            onClick={handleSubmit(update)}
          >Save</Button>
        </div>

        {loading && <LinearProgress className="w-full mt-4"/>}
      </form>
    </div>
  )
}
