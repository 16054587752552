import React, { useContext, useState } from "react"
import AppContext from "../../context/AppContext"
import { saveConfig } from "../../service/faqService"
import { Button, Divider, TextField } from "@mui/material"
import { Translations } from "../../model/Config"
import { Controller, useForm, useFormState } from "react-hook-form"
import { translations } from "../../util/translations"
import { useObjectChangeEffect } from "../../util/useObjectChangeEffect"

export const MessageTranslations = () => {

  const [templates, setTemplates] = useState({})

  const context = useContext(AppContext)
  const guildId = context.guild?.id

  useObjectChangeEffect(() => {
    if (context.config?.templates) {
      setTemplates(context.config?.templates)
    }
  }, [context.config?.templates])

  const update = async (translations: Translations) => {
    await saveConfig(guildId, { templates: translations })
      .finally(() => {
        // @ts-ignore
        context.setConfig({
          ...context.config,
          templates: translations,
        })
      }).catch(err => {
        alert("Error saving, please try again (later)")
        window.location.reload()
      })
  }

  return (
    <div className="text-center mt-0 mb-10 mx-4">
      <h1 className="text-2xl my-2">Translations</h1>
      <p className="my-4">
        If the bot stops responding after editing the messages, please revert the changes and try again.
        <br/>
        It may be that the message is too long, or that the message contains invalid characters.
      </p>
      <Divider className="my-2"/>
      <div className="mt-2 mb-4">
        <h2 className="my-2">Premade Translations</h2>
        <TranslationSelector
          onSelect={(lang, templates) => setTemplates(templates)}
        />
      </div>
      {Object.entries(templates).map(([key, value]) => (
        <div key={key} className="my-4 max-w-[600px] m-auto">
          <h2 className="text-left text-gray-400 text-xl my-2">{key}</h2>
          <TranslationForm
            values={value}
            update={async translation => {
              await update({ ...context.config?.templates, [key]: translation })
            }}
          />
        </div>
      ))}
    </div>
  )

}


const TranslationForm = ({ values, update }: any) => {

  const { handleSubmit, control, reset } = useForm<any>({ defaultValues: values })
  const { isDirty, errors, isValid } = useFormState({ control })

  const submit = (values) => {
    update(values).then(() => reset(values))
  }

  useObjectChangeEffect(() => {
    // reset and make dirty
    reset(values, { keepDefaultValues: true })
  }, [values])

  return (
    <div>
      <form onSubmit={handleSubmit(update)}>
        {Object.entries(values).map(([key, initialValue]) => (
          <div key={key} className="my-4">
            <Controller
              rules={{ required: true, maxLength: 100 }}
              name={key}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  error={!!errors?.[key]}
                  helperText={errors?.[key]?.message}
                  className="w-full"
                  onChange={onChange}
                  value={value}
                  maxRows={2}
                  label={key}
                  multiline
                />
              )}
            />
          </div>
        ))}
        <div className="flex flex-row justify-end items-center space-x-4">
          <Button
            disabled={!isDirty}
            variant={"outlined"}
            onClick={() => reset()}
          >Reset</Button>
          <Button
            disabled={!isDirty}
            variant={"contained"}
            onClick={handleSubmit(submit)}
          >Save</Button>
        </div>
      </form>
    </div>
  )
}

export const TranslationSelector = ({ onSelect }: any) => {

  const [selected, setSelected] = useState<string | undefined>()

  return (
    <div className="flex flex-row justify-center items-center gap-1 flex-wrap w-2/3 m-auto">
      {translations.map(translation => (
        <Button
          style={{ fontSize: "1.2rem", padding: 0 }}
          key={translation.language}
          variant={selected === translation.language ? "contained" : "outlined"}
          size="small"
          onClick={() => {
            setSelected(translation.language)
            onSelect(translation.language, translation.translations)
          }}
        >{translation.icon}</Button>
      ))}
    </div>
  )
}
