import React, { Suspense, useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { EditFAQsPage } from "./components/EditFAQsPage"
import GuildSelector from "./components/GuildSelector"
import { Divider, LinearProgress, Paper, Typography } from "@mui/material"
import AppContext, { AppContextInterface, defaultAppContext } from "./context/AppContext"
import useRequest from "./useRequest"
import { getServerData, ServerDataResponse } from "./service/faqService"
import { Route, Routes } from "react-router-dom"
import Checkout from "./Checkout"
import useSearchParams from "./util/useSearchParams"
import AccountMenu from "./components/AccountMenu"
import { useViewport } from "./util/useViewport"
import { HOME_WEBSITE_URL } from "./config/urls"
import { UsefulLinks } from "./components/UsefulLinks"
import { ErrorBoundary } from "./components/common/ErrorBoundary"

const LOCAL_STORAGE_GUILD_KEY = "guild_id"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {

  const context = defaultAppContext
  context.setGuild = guild => setAppContext(prev => ({ ...prev, guild }))
  context.setFAQs = faqs => setAppContext(prev => ({ ...prev, faqs }))
  context.setConfig = config => setAppContext(prev => ({ ...prev, config }))
  context.setUser = user => setAppContext(prev => ({ ...prev, user }))

  const [appContext, setAppContext] = useState<AppContextInterface>(context)

  const { guild } = appContext

  const { loading, data, error } = useRequest<ServerDataResponse>(() => getServerData(guild?.id || ""), { depends: [guild] })

  const { isMobile } = useViewport()
  const [searchParams, updateSearchParams] = useSearchParams()
  const initialGuild = getInitialGuild(searchParams)

  useEffect(() => {
    const faqs = data?.faqs || []
    context.setFAQs(error || faqs)
    context.setConfig(data?.config || {})
  }, [data, error])

  useEffect(() => {
    if (guild) {
      localStorage.setItem(LOCAL_STORAGE_GUILD_KEY, guild?.id)
      updateSearchParams({ guild: guild.id })
      document.title = `FAQs - ${guild.name}`
    }
  }, [guild])

  return (
    <AppContext.Provider value={appContext}>
      <ThemeProvider theme={darkTheme}>
        <div className="mt-10 w-[95%] md:w-4/5 m-auto text-center">
          <div className="flex w-full flex-wrap">
            <div className="w-full md:w-fit">
              <Typography
                color="primary"
                className="text-left text-blue"
                style={{ fontSize: isMobile ? "1.25rem" : '2rem' }}
              >
                <a href={HOME_WEBSITE_URL} target="_blank">
                  <img src="/logo.png" alt="logo" className="inline-block w-12 h-12 mr-2 mb-2"/>
                  WalluBot.com
                </a>
                <span> - Server Panel</span>
              </Typography>
              <Divider/>
            </div>

            <div className="mx-auto mt-4 lg:mt-0">
              <UsefulLinks/>
            </div>

          </div>
          <div className="text-end mt-4 ml-[80%] lg:ml-[100%]">
            <AccountMenu/>
          </div>

          <div>
            <Paper className="mt-8 mb-16 p-8 w-full: md:w-3/5">
              <GuildSelector initialGuild={initialGuild}/>
            </Paper>

            <div className="mb-6">
              {loading && <LinearProgress/>}
            </div>


            <Suspense
              fallback={<p className="text-gray-500">Loading...</p>}
            >
              <ErrorBoundary>
                <Routes>
                  <Route path="/checkout" element={<Checkout/>}/>
                  <Route path="/" element={<EditFAQsPage loading={loading}/>}/>
                </Routes>
              </ErrorBoundary>
            </Suspense>
          </div>
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

const getInitialGuild = (searchParams) => {
  const paramGuild = searchParams.get("guild") as string
  if (paramGuild) {
    // Set initial guild from params, so we will select the correct guild if none was set in local storage yet
    if (!localStorage.getItem(LOCAL_STORAGE_GUILD_KEY)) {
      localStorage.setItem(LOCAL_STORAGE_GUILD_KEY, paramGuild)
    }
    return paramGuild
  }
  return localStorage.getItem(LOCAL_STORAGE_GUILD_KEY)
}

export default App
