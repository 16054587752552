import { useEffect, useState } from "react"

export default function useRequest<T>(loader: () => Promise<T>, { depends } = {}) {

  const [data, setData] = useState<T>()
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(0)

  useEffect(() => {
    setLoading(prev => prev + 1)
    const load = async () => {
      setError(undefined)
      const it = await loader()
      setData(it)
      setError(undefined)
    }
    load()
      .catch(err => setError(err))
      .finally(() => setLoading(prev => prev - 1))
  }, depends || [])

  return {
    loading: loading > 0,
    data,
    error,
  }

}
