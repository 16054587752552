import Guild from "../model/Guild"
import { FAQ } from "../model/FAQ"
import React from "react"
import { Config } from "../model/Config"
import User from "../model/User"

export interface AppContextInterface {
  guild: Guild | undefined
  faqs: FAQ[]
  config: Config | undefined
  user: User | undefined
  setConfig: (config: Config) => any
  setGuild: (guild: Guild) => any
  setFAQs: (faqs: FAQ[]) => any
  setUser: (user: User) => any
}


export const defaultAppContext = {
  guild: undefined,
  faqs: [],
  config: undefined,
  user: undefined,

  setConfig: (config: Config) => {
  },
  setGuild: (guild: Guild) => {
  },
  setFAQs: (faqs: FAQ[]) => {
  },
  setUser: (user: User) => {
  }


}

export default React.createContext<AppContextInterface>(defaultAppContext)
