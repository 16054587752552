import React, { useContext, useState } from "react"
import AppContext from "../../context/AppContext"
import { saveConfig } from "../../service/faqService"
import useRequest from "../../useRequest"
import { ChannelsResponse, getChannels } from "../../service/discordService"
import { Channel } from "../../model/Channel"
import { Autocomplete, Button, FormControl, LinearProgress, TextField } from "@mui/material"
import { deepEquals } from "../../util/util"

export const SelectRespondChannels = () => {

  const { guild, config, setConfig } = useContext(AppContext)

  const saveChannels = async (channels: string[]) => {
    await saveConfig(guild?.id!!, { ...config, enabled_channels: channels })
      .finally(() => {
        // @ts-ignore
        setConfig({ ...config, enabled_channels: channels })
      })
      .catch(err => {
        alert("Failed to save channels: " + err)
      })
  }

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold">Select Channels</h2>
      <p className="text-sm text-gray-300">
        Select the channels where the bot will respond to questions.
        <br/>
        Note the bot should have permission to read and send messages and add reactions to messages.
      </p>

      <div className="my-12 w-2/3 m-auto">
        {config && guild && (
          <ChannelSelector guild={guild} selectedChannels={config.enabled_channels} saveChannels={saveChannels}/>
        )}
      </div>
    </div>
  )
}

const ChannelSelector = ({ guild, selectedChannels, saveChannels }: any) => {

  const [channels, setChannels] = useState<string[]>(selectedChannels)

  const { loading, data, error } = useRequest<ChannelsResponse>(() => getChannels(guild?.id || ""), { depends: [guild] })
  const loadingOptions: Channel = { id: "LOADING", name: "Loading...", position: -2, type: "other", category: "", has_send_messages_permission: false }

  const otherOptions: Channel[] = [
    { category: "Other Options", name: 'All threads on this server', id: 'ALL_THREADS', position: -1, has_send_messages_permission: true, type: 'other' },
    { category: "Other Options", name: 'Support channels (automatic detection by name)', id: 'SUPPORT_CHANNELS', position: -1, has_send_messages_permission: true,type: 'other' },
    ...(data?.channels?.filter(it => it.type === 'category').map(it => {
      return {
        ...it,
        name: `All channels in "${it.name}" category`,
        position: -1,
        category: 'By Category',
        type: 'other',
      }
    }) || [])
  ]
  const options = (data?.channels ? [...otherOptions, ...data?.channels] : [loadingOptions])
    // sort by position, at the same time it sorts by category  for grouping to work
    .filter(it => it.type !== "category")
    .sort((a, b) => a.position - b.position)

  // @ts-ignore
  const value: Channel[] = channels.map(it => options.find(c => c.id === it) || { id: -1, position: -3, name: "Deleted/Unknown Channel?" })

  return (
    <div>
      {loading && <LinearProgress className="mb-4"/>}
      {error && <p className="text-red-500">Error loading channels</p>}
      {options && (
        <FormControl fullWidth>
          <Autocomplete
            value={value}
            isOptionEqualToValue={(a, b) => a?.id === b?.id}
            getOptionLabel={(option) => {
              const name = option?.name || "deleted/invalid?"
              if (!option?.has_send_messages_permission) {
                return `${name} (no permission)`
              }
              return name
            }}
            onChange={(e, value) => {
              value && setChannels(value.map(v => v.id))
            }}
            groupBy={option => option.category || ""}
            options={options}
            loading={loading}
            noOptionsText="Channel not found. Does it have the correct permissions?"
            multiple
            disableCloseOnSelect
            autoHighlight
            limitTags={3}
            getOptionDisabled={g => !g.has_send_messages_permission}
            renderInput={(params) => (
              <TextField
                {...params}
                rows={2}
                label={loading ? "Loading..." : (channels?.length === 0 ? "All channels are enabled" : "The bot will only respond in these channels")}
              />
            )}
          />
        </FormControl>
      )}

      <div className="mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => saveChannels(channels)}
          disabled={deepEquals(selectedChannels, channels)}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

