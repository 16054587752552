import User from "../model/User"
import client from "../config/axios"
import Guild from "../model/Guild"

export async function getProfile(): Promise<{ user: User, guilds: Guild[] }> {
  const data = await client.get("/auth/me")
  return data.data
}

export async function logout({ redirect }) {
  await client.post("/auth/logout")
  window.location.href = redirect
}
