import React from "react"
import { FAQ } from "../model/FAQ"
import { EditFAQ } from "./EditFAQ"
import { VariableSizeList as List } from 'react-window'
import { ReactWindowScroller } from "./common/WindowScroll"


export const ListFAQs = ({
                           faqs,
                           save,
                           deleteFAQ
                         }: { faqs: FAQ[], save: (faq: FAQ) => Promise<FAQ>, deleteFAQ: (faq: FAQ) => Promise<any> }) => {

  const sortedFaqs = faqs.sort((a, b) => String(b.created_at).localeCompare(a.created_at))

  return (
    <div>
      <ReactWindowScroller throttleTime={200}>
        {({ ref, outerRef, style, onScroll }) => (
          <List
            style={{ overflow: 'visible', ...style }}
            ref={ref}
            outerRef={outerRef}
            onScroll={onScroll}
            height={window.innerHeight}
            width={"100%"}
            itemCount={sortedFaqs.length}
            overscanCount={4}
            itemKey={index => sortedFaqs[index].id}
            estimatedItemSize={530}
            itemSize={() => 530}
            initialScrollOffset={1000}
          >
            {({ index, style }) => {
              const faq = sortedFaqs[index]
              return (
                <div style={style} className="my-[15px]" key={faq.id}>
                  <EditFAQ
                    deleteFAQ={async () => await deleteFAQ(faq)}
                    faq={faq}
                    save={save}
                  />
                </div>
              )
            }}
          </List>
        )}
      </ReactWindowScroller>
    </div>
  )
}
