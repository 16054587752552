import React, { useContext, useState } from "react"
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { saveConfig } from "../../service/faqService"
import AppContext from "../../context/AppContext"
import { Tooltip } from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"

export default function ModelSelector({ guildId }: any) {

  const context = useContext(AppContext)

  const [loading, setLoading] = useState(false)

  const update = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    await saveConfig(guildId, { ...context.config, model: e.target.value as any })
      .finally(() => {
        context.setConfig({
          ...context.config,
          model: e.target.value as any,
        })
        setLoading(false)
      }).catch(err => {
        // rate limit
        if (err.response.status === 429) {
          alert("Please do not spam and wait a minute before trying again.")
          window.location.reload()
        } else {
          alert("Error saving, please try again")
          window.location.reload()
        }
      })
  }

  const initialValue = context.config?.model
  if (!initialValue) return null

  return (
    <FormControl disabled={loading}>
      <FormLabel id="language-model-selector">
        My members speak:
      </FormLabel>
      <RadioGroup
        aria-labelledby="language-model-selector"
        defaultValue={initialValue}
        onChange={update}
      >
        <FormControlLabel value="default" control={<Radio/>} label="English only"/>
        <FormControlLabel
          value="multilingual"
          control={<Radio/>}
          label={
            <span>
              <span>Other languages </span>
              <Tooltip
                title={
                  <span className="text-base">
                    Select "other languages" if all your FAQs are not in English. This will help the bot better understand non-English questions.
                    <br/>
                    <br/>
                    Check supported languages <a href="https://wallubot.com/#multilingual" className="text-blue-300" target="_blank"
                                                 rel="noreferrer">here</a>.
                  </span>
                }>
              <span><HelpIcon fontSize="small"/></span>
              </Tooltip>
            </span>
          }/>
      </RadioGroup>
    </FormControl>
  )
}
