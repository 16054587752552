import { FAQ } from "../model/FAQ"
import React, { useCallback, useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { debounce, InputAdornment, StandardTextFieldProps, TextField } from "@mui/material"
import useSearchParams from "../util/useSearchParams"

export function useSearch(faqs: FAQ[]) {

  const [search, setSearch] = useState("")

  if (search) {
    faqs = faqs.filter(it => {
      const searchData = it.question + it.answer + it.alternatives.map(it => it.question).join(" ")
      return searchData.toLowerCase().includes(search.toLowerCase())
    })
  }

  return {
    faqs,
    search,
    setSearch: (search) => setSearch(search?.toLowerCase())
  }

}

interface SearchFieldProps extends StandardTextFieldProps {
  onSearchUpdate: (search: string) => any
}

export default function SearchField({ onSearchUpdate, ...otherProps }: SearchFieldProps) {

  const [queryParams, updateSearchParams] = useSearchParams()
  const [search, setSearch] = useState(queryParams.get('search') || '')

  const updateSearch = useCallback(debounce(value => {
    onSearchUpdate(value)
    updateSearchParams({ search: value })
  }, 300), [])

  useEffect(() => {
    updateSearch(search)
  }, [search])

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon/>
          </InputAdornment>
        ),
        className: "text-gray-400",
        style: { color: 'grey' }
      }}
      InputLabelProps={{ className: "text-gray-400", }}
      name="search"
      type="search"
      placeholder="Search..."
      value={search}
      onChange={e => setSearch(e.target.value)}
      {...otherProps}
    />
  )
}
