import { useEffect, useState } from "react"

export const useViewport = () => {

  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = () => setWidth(window.innerWidth)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  const viewportReady = width !== -1
  return {
    isViewportReady: viewportReady,
    isMobile: width <= 768 && viewportReady,
    isDesktop: width > 768 && viewportReady,
  }
}
