import { Button, Dialog, DialogContent, LinearProgress } from "@mui/material"
import CloseButton from "./common/CloseButton"
import VoteIcon from '@mui/icons-material/ThumbUp'
import React, { useEffect, useState } from "react"

export const VoteBotModal = () => {

  const [open, setOpen] = useState(false)
  const [canClose, setCanClose] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setLastVoted().then()
      if (loading) {
        setTimeout(() => {
          setOpen(false)
          setLoading(false)
        }, 10_000)
      }
    }
  }, [loading, open])

  useEffect(() => {
    if (!open) {
      getLastVoted().then(lastVoted => {
        const canVoteAgain = lastVoted ? new Date(lastVoted).getTime() + (3 * 24 * 60 * 60 * 1000) < new Date().getTime() : true
        if (canVoteAgain) {
          setOpen(true)
        }
      })
    }
  })

  useEffect(() => {
    if (open) {
      setCanClose(false)
      setTimeout(() => {
        setCanClose(true)
      }, 2_000)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(e, r) => {
        if (r !== "backdropClick") {
          setOpen(false)
        }
      }}
      fullWidth
      BackdropProps={{
        classes: {
          root: "blur-sm bg-[#00001e] backdrop-blur-sm opacity-40",
        },
      }}
    >
      <div className="ml-auto">
        {canClose && <CloseButton onClick={() => setOpen(false)}/>}
      </div>
      <DialogContent>
        <div className="text-2xl font-bold text-center">Adding more FAQs?</div>
        <div className="text-center my-6">
          Please consider voting the bot quickly before adding more FAQs.
          <br/>
          It will take less than a minute.
        </div>
        <div className="mt-10 w-2/3 mx-auto text-center">
          <div className="">
            <Button href="https://top.gg/bot/973282202894872696/vote" target="_blank" rel="noreferrer" disabled={loading}
                    className="mt-4" variant="contained" color="success"
                    onClick={() => setLoading(true)}
                    startIcon={<VoteIcon/>}
            >
              Vote
            </Button>
          </div>
          <div className="w-3/4 m-auto mt-4">
            {loading && <LinearProgress/>}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}


// Good enough for now
async function getLastVoted() {
  return window.localStorage.getItem("last-vote-prompt")
}

async function setLastVoted() {
  window.localStorage.setItem("last-vote-prompt", new Date().toISOString())
}
