import React, { useContext, useEffect } from "react"
import AppContext from "./context/AppContext"
import { NotOnThatServer } from "./components/EditFAQsPage"
import { ManageSubscriptionButton } from "./components/ManageSubscriptionButton"
import { BASE_URL } from "./config/axios"

export default function Checkout() {

  const { guild, config } = useContext(AppContext)

  useEffect(() => {
    if (!guild?.id) {
      return
    }
    window.location.href = getCheckoutUrl(guild.id)
  }, [guild])

  if (!guild?.id) {
    return null
  }

  if (!config?.limits) {
    return <NotOnThatServer header={<div/>}/>
  }

  return (
    <div>
      <h2 className="text-2xl text-gray-300">Redirecting...</h2>
      <p className="text-2xl text-gray-300">
        You will be redirected to the checkout page in a few seconds.
        <br/>
        If you are not redirected, click the button below.
      </p>
      <div className={"mt-12"}>
        <ManageSubscriptionButton/>
      </div>
    </div>
  )
}

export const getCheckoutUrl = guild_id => {
  return BASE_URL + '/checkout/' + guild_id
}
