import React, { useContext } from "react"
import AppContext from "../context/AppContext"
import { Button } from "@mui/material"
import { BASE_URL } from "../config/axios"
import PremiumIcon from "@mui/icons-material/WorkspacePremium"
import { Config } from "../model/Config"
import { PLANS_WEBSITE_URL } from "../config/urls"

export const ManageSubscriptionButton = () => {

  const { guild, config } = useContext(AppContext)

  if (isPremium(config)) {
    return (
      <Button
        variant="contained"
        color="primary"
        href={getBillingPageURL(guild?.id)}
        style={{ backgroundColor: '#00d1b2' }}
        startIcon={<PremiumIcon/>}
        target="_blank"
      >
        Manage billing
      </Button>
    )
  }

  return (
    <Button
      href={PLANS_WEBSITE_URL}
      variant="contained"
      style={{ backgroundColor: '#00d1b2' }}
      startIcon={<PremiumIcon/>}
      target="_blank"
    >
      Go Premium!
    </Button>
  )
}

export const isPremium = (config: Config) => config?.disable_ads


export const getBillingPageURL = (guildId) => `${BASE_URL}/stripe/billing/${guildId}`
