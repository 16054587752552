import { useEffect, useRef } from "react"

function useDeepCompareMemoize(value) {
  const ref = useRef()
  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value
  }
  return ref.current
}

/**
 * like useEffect, but only triggers if the object changes (deep compare)
 */
export const useObjectChangeEffect = (callback, dependencies: any[]) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
