import React, { useContext, useEffect } from 'react'
import { fetchSuggestions, SuggestionsResponse } from "../service/faqService"
import LinkIcon from "@mui/icons-material/Link"
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import CopyToClipboard from "./common/CopyToClipboard"
import AddIcon from '@mui/icons-material/AddBox'
import useRequest from "../useRequest"
import AppContext from "../context/AppContext"

export default function Suggestions({ refreshId, guildId,addQuestion }: any) {

  const theme = useTheme()

  const { guild } = useContext(AppContext)

  const { loading, data, error } = useRequest<SuggestionsResponse>(() => fetchSuggestions(guild?.id || ""), { depends: [guild, refreshId] })

  const suggestions = data?.suggestions || []

  if (loading) {
    return (
      <div>
        <Typography variant="h5" className="text-gray-700">Loading suggestions...</Typography>
      </div>
    )
  }

  if (!Object.keys(suggestions).length) {
    return (
      <div>
        <Typography variant="h5" className="text-gray-700">No suggested FAQs found</Typography>
      </div>
    )
  }

  return (
    <Paper className="p-8 h-full">
      <Typography
        color="secondary"
        style={{
          margin: '0 0 8px 0',
          fontSize: '22px',
          textAlign: 'start'
        }}
      >Suggested FAQs</Typography>
      <div className="max-h-[340px] overflow-auto rounded">
        <Table>
          <TableHead></TableHead>
          <TableBody>
            {suggestions.map((suggestion, index) => (
              <TableRow
                key={suggestion.message_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} // Hide last divider
                style={{
                  background: index % 2 ? theme.palette.grey["900"] : theme.palette.background.paper
                }}
              >
                <TableCell className="md:w-[500px]">
                  <i>{suggestion.question}</i>
                  {/*Add mark with tooltip if it has an answer*/}
                  {suggestion.answer?.length > 0 && <span className="text-green-500 ml-1" title="This suggestion has an answer">✓</span>}
                </TableCell>

                <TableCell>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => addQuestion(suggestion)}
                    endIcon={<AddIcon/>}
                    title="Click to add as a question to the new FAQ"
                  >Add</Button>
                </TableCell>
                <TableCell>
                  <CopyToClipboard>
                    {({ copy }) => (
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => copy(suggestion)}
                        endIcon={<LinkIcon/>}
                      >Copy</Button>
                    )}
                  </CopyToClipboard>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    </Paper>
  )
}
